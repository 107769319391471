import { useQuery } from "@tanstack/react-query";
import TVChart from "../components/test/TVChart";
import { fetchHistoricChart } from "../util/http";
import Loading from "../components/Loading";
import Error from "../components/Loading";
import StandardChart from "../components/StandardChart";
import { Box } from "@mui/material";


export default function TestPage(){

    const ticker = "SPX";
    const metrics = ['call_vol', '-put_vol'];
    const { data: { data: chartData } = { data: [] }, isPending, isFetching, isError, error } = useQuery({
        queryKey: ['historicalChart', 'volume', ticker, [...metrics]],
        queryFn: () => fetchHistoricChart(ticker, metrics, 'price=close', null, null),   
        refetchOnMount: false,
    });

    if (isPending || isFetching)
        return <Loading />;

    if (isError)
        return <Error error={error} />;


    //console.log('data: ', chartData);

    const option = {   
        //...sharedOption,
        grid: {
            left: '10px', //'4%',
            right: '25px', //'12%',
            bottom: '25px',//'20%',
            top: '30px',//'25%' 
            containLabel: true,
        },
        legend: {
            data: Object.keys(chartData).filter(key => key !== 'Date' && key !== 'Price'),
            icon: 'circle',
            top: '8px',//'6%',
            left: 'center',
        },
        xAxis: 
        {
            type: 'category',
            data: chartData['Date'],
            boundaryGap: true,
            axisPointer: {
                type: "shadow",
                show: true,
                label: {show: true},
            },    
            axisTick: {
                show: false
            },
            // axisLabel: {
            //     maxLabels: 15,
            //     interval: Math.round(numDataPoints / 15) - 1,
            //     rotate: 30,
            //     fontSize: 6,
            // },
        },
        yAxis: [
            {
                position: 'right',
                type: 'value',
                splitLine: {
                    show: false,
                },
                axisLabel: {
                    formatter: function (value) {
                        //console.log(value);
                        if (value && value % 1000000 === 0)
                            return (value / 1000000) + 'M';
                        else if (value && value && value % 1000 === 0)
                            return (value / 1000) + 'K';
                        else
                            return value;
                    },
                    fontSize: 8,
                },                
            },
        ], 
        series: metrics.map((metric) => ({
            name: metric === 'call_vol' ? 'CALL VOL' : 'PUT VOL',
            data: chartData[metric === 'call_vol' ? 'CALL VOL' : 'PUT VOL'],
            type: 'bar',
            stack: 'Volume', 
            areaStyle: {},
            symbol: 'none',
            smooth: true,
            large: true,
        })), 
        dataZoom: [
            {
                type: "inside",
                xAxisIndex: 0,
                start:0,
                end: 100,
            }
        ]
    };       

    return (
        <>
            <TVChart data={chartData}/>
            <Box sx={{height:'400px', border:'3px solid white'}}>
                <StandardChart option={option} style={{height:'400px'}}/>
            </Box>
        </>
    );
}