import { useQuery } from "@tanstack/react-query";
import { fetchAreaOfInterestChart } from "../../util/http";
import Loading from "../Loading";
import Error from "../Loading";
import AreasOfInterestChart from "../areas_of_interest/AreasOfInterestChart";

export default function PositionSimulatorChart({params}){

    const { data: { data: chartData } = { data: [] }, isPending, isError, error } = useQuery({
        queryKey: ['areaOfInterestsChart', params?.ticker, 'custom', params?.date, params?.expirationFrom, params?.cutoff],
        queryFn: () => fetchAreaOfInterestChart(params?.ticker, 'custom', params?.date, params?.expirationFrom, params?.cutoff),
        enabled: params !== null,
    });

    if (params === null) return;
    if (isPending)
        return <Loading/>;
    if (isError)
        return <Error error={error}/>

    return (
        <AreasOfInterestChart
            ticker={params.ticker}
            title={params.ticker}
            chartData={chartData['custom'][params.ticker]}
        />                                                    

    );
}