import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import { Grid, Drawer, List, ListItem, ListItemIcon } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PlayArrowIcon from '@mui/icons-material/PlayArrow'; 
import ScienceIcon from '@mui/icons-material/Science'; 
import ChartsPage from './ChartsPage';
import MarketBreadthPage from './MarketBreadthPage';
import { useQuery } from '@tanstack/react-query';
import Loading from '../components/Loading';
import IndexOverviewPage from './IndexOverviewPage';
import PositioningSimulatorPage from './PositioningSimulatorPage';
import TestPage from './TestPage';

async function waitForWPNonce()
{
  if (process.env.NODE_ENV === 'development')
      return true;
    
  let waitingTime = 2000;
  const interval = 50;    

  while (!window.WP_NONCE && waitingTime > 0) 
  {       
    await new Promise(resolve => setTimeout(resolve, interval));
    waitingTime -= interval;
  }

  if (!window.WP_NONCE)
  {
    const error = new window.Error("Error while trying to fetch WP Nonce!");
    //error.code = 404;
    //error.info = 'WP Nonce is null!';

    throw error;
  }

  return true;
}

const LayoutContainer = () => {
  const [hasWPAdminBar, setWPAdminBar] = useState(false);


  useEffect(() => {
    const wpAdminBar = document.getElementById('wpadminbar');
    setWPAdminBar(wpAdminBar !== null);
  }, []);

  const { isPending: isWPNoncePending, isError: isWPNonceError } = useQuery({
    queryKey: ['wait wp nonce'],
    queryFn: () => waitForWPNonce(),   
  });  

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development' && !isWPNoncePending && isWPNonceError)
      window.location.href = 'https://www.bbbeacon.com/login';
  }, [isWPNoncePending, isWPNonceError]);  
  


  if (isWPNoncePending)
    return <Loading />;

  return (
    <Router>
      <Grid container>
        {/* Sidebar */}
        <Grid item sx={{width:'50px', flexShrink: 0 }}>
          <Drawer id='sidebar'
            variant="permanent"   
            sx={{
              width: '50px', //3.57vw
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: { width: '50px', boxSizing: 'border-box', marginTop: hasWPAdminBar ? '35px' : '0px' }, 
            }}
          >
            <List
                sx={{
                  '& > .MuiListItem-root': {
                    '&.active': {
                      backgroundColor: 'rgba(211, 211, 211, 0.5)',
                    }
                  }
                }}            
            >
              <ListItem button component={NavLink} to="/">
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
              </ListItem>
              <ListItem button 
                  component={NavLink} 
                  to="/market-breadth"
              >
                <ListItemIcon>
                  <ShowChartIcon />
                </ListItemIcon>
              </ListItem>
              <ListItem button 
                  component={NavLink} 
                  to="/index-overview"
              >
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
              </ListItem>              
              <ListItem button 
                  component={NavLink} 
                  to="/positioning-simulator"
              >
                <ListItemIcon>
                  <PlayArrowIcon />
                </ListItemIcon>
              </ListItem>              

              <ListItem button 
                  component={NavLink} 
                  to="/test"
              >
                <ListItemIcon>
                  <ScienceIcon />
                </ListItemIcon>
              </ListItem>              


            </List>
          </Drawer>
        </Grid>

        {/* Main Content */}
        <Grid item xs>
          <Routes>
            <Route path="/" element={<ChartsPage />} />
            <Route path="/market-breadth" element={<MarketBreadthPage />} />
            <Route path="/index-overview" element={<IndexOverviewPage />} />
            <Route path="/positioning-simulator" element={<PositioningSimulatorPage />} />
            <Route path="/test" element={<TestPage />} />
          </Routes>
        </Grid>
      </Grid>
    </Router>
  );
};

export default LayoutContainer;
