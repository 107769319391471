import React, { useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

export default function TVChart({data}){

    const chartContainerRef = useRef(null);

    useEffect(() => {
        const chart = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.offsetWidth,
            height: 400,
            layout: {
                background: {
                    color: '#000000'
                },
                textColor: '#ffffff',
            },
            grid: {
                vertLines: {
                    visible: false,
                },
                horzLines: {
                    visible: false,
                },
            },            
        });

        const callSeries = chart.addHistogramSeries({
            color: '#00BFFF',
        });
        const putSeries = chart.addHistogramSeries({
            color: '#FFA500',
        });


        callSeries.setData(
            data.Date.map((date, index) => ({
                time: dayjs(date).format('YYYY-MM-DD'),
                value: data['CALL VOL'][index],
            }))
        );
        putSeries.setData(
            data.Date.map((date, index) => ({
                time: dayjs(date).format('YYYY-MM-DD'),
                value: data['PUT VOL'][index],
            }))
        );

        chart.timeScale().fitContent();

        return () => {
            chart.remove();
        };
    }, [data]);

    return <Box ref={chartContainerRef} style={{ position: 'relative', width: '100%' }} />;
}