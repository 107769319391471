import { Box, Button, FormControl, FormGroup } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import Loading from "../Loading";
import Error from "../Error";
import { fetchAssets, fetchAssetStartDate, getUserMeta, setUserMeta } from "../../util/http";
import TickerSelector from "../TickerSelector";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { isMarketClosed } from "../../util/Util";
import { SAVE_KEY_POSITION_SIMULATOR_ENTRIES } from "../../util/Constants";

export default function PositionSimulatorPanel({handleRunSimulation}){
    
    const {data: { data: { assets: tickerList } = {} } = {}, isPending: isTickerListPending, isError: isTickerListError, error: tickerListError} = useQuery({
        queryKey: ['assets list'],
        queryFn: () => fetchAssets(),
        staleTime: Infinity,
    });

    const { data: savedEntries, isPending:isSavedEntriesPending, isError: isSavedEntriesError, error: savedEntriesError } = useQuery({
        queryKey: ['user_meta'],
        queryFn: () => getUserMeta(),
        select: (data) => data ? data[SAVE_KEY_POSITION_SIMULATOR_ENTRIES] : null,
    });



    const [ticker, setTicker] = useState(null);
    const [date, setDate] = useState(null);
    const [expirationFrom, setExpirationFrom] = useState(null);
    const [cutoff, setCutoff] = useState(null);
    const [tickerStartDate, setTickerStartDate] = useState(null);

    const {data: startDateFromServer, isPending: isStartDatePending, isError: isStartDateError, error: startDateError} = useQuery({
        queryKey: ['asset start date', ticker],
        queryFn: () => fetchAssetStartDate(ticker),
        enabled: ticker !== null,
    });    

    useEffect(() => {
        if (!isStartDatePending && !isStartDateError && startDateFromServer){
            setTickerStartDate(dayjs(startDateFromServer.data.start_date));
        }
    }, [startDateFromServer, isStartDatePending, isStartDateError, ticker]);

    const handleTickerSelected = useCallback((event, value) => {
        if (ticker !== value){            
            setTicker(value);
        }
    }, [ticker]);

    useEffect(() => {
        if (!isSavedEntriesPending && !isSavedEntriesError && savedEntries)
        {
            setTicker(savedEntries.ticker);
            setDate(savedEntries.date);
            setExpirationFrom(savedEntries.expirationFrom);
            setCutoff(savedEntries.cutoff);

            if (savedEntries.ticker && savedEntries.date && savedEntries.expirationFrom){
                handleRunSimulation(savedEntries);        
            }
        }
    }, [isSavedEntriesPending, isSavedEntriesError, savedEntries, handleRunSimulation]);

    const handleDateChanged = useCallback(value => {
        const newDate = dayjs(value);
        
        if (!dayjs(date).isSame(newDate)){
            
            setDate(newDate.format('YYYYMMDD'));

            if (!expirationFrom || dayjs(expirationFrom).isBefore(newDate))
            {     
                setExpirationFrom(newDate.format('YYYYMMDD'));
            }
            if (cutoff && dayjs(cutoff).isBefore(newDate))
                setCutoff(newDate.format('YYYYMMDD'));
        }        
    }, [date, cutoff, expirationFrom]);

    const handleExpirationFromChanged = useCallback(value => {
        const newExpFrom = dayjs(value);
        
        if (!dayjs(expirationFrom).isSame(newExpFrom)){
            setExpirationFrom(newExpFrom.format('YYYYMMDD'));

            if (!date || dayjs(date).isAfter(newExpFrom))
                setDate(newExpFrom);            
            if (cutoff && dayjs(cutoff).isBefore(newExpFrom))
                setCutoff(null);            

        }

    }, [date, cutoff, expirationFrom]);

    const handleCutoffChanged = useCallback(value => {
        const newCutoff = dayjs(value);
        
        if (!dayjs(cutoff).isSame(newCutoff) && !newCutoff.isBefore(dayjs(expirationFrom))){
            setCutoff(newCutoff.format('YYYYMMDD'));
        }

    }, [expirationFrom, cutoff]);

    const handleReset = useCallback(() => {
        setTicker(null);
        setDate(null);
        setExpirationFrom(null);
        setCutoff(null);
        setUserMeta(SAVE_KEY_POSITION_SIMULATOR_ENTRIES, { 
            ticker: null,
            date: null,
            expirationFrom: null,
            cutoff: null
        });

    }, []); 

    const handleSubmit = useCallback((event) => {
        event.preventDefault();
        setUserMeta(SAVE_KEY_POSITION_SIMULATOR_ENTRIES, { 
            ticker,
            date,
            expirationFrom,
            cutoff
        });
        handleRunSimulation({
            ticker,
            date,
            expirationFrom,
            cutoff,
        });
    }, [ticker, date, expirationFrom, cutoff, handleRunSimulation]);    

    const shouldDisableDate = useCallback((date) => {
        return dayjs(date).isSame(dayjs()) || dayjs(date).isAfter(dayjs()) || dayjs(date).isBefore(tickerStartDate) || isMarketClosed(date);
    }, [tickerStartDate]);    

    const shouldDisableExpFrom = useCallback((expFrom) => {
        return dayjs(expFrom).isBefore(dayjs(date));
    }, [date]);    

    const shouldDisableCutoff = useCallback((cutoff) => {
        return dayjs(cutoff).isBefore(dayjs(expirationFrom));
    }, [expirationFrom]);    
    
    if (isTickerListPending || isSavedEntriesPending)
        return <Loading/>;

    if (isTickerListError){
        return <Error error={tickerListError}/>
    }

    if (isStartDateError){
        return <Error error={startDateError}/>
    }

    if (isSavedEntriesError){
        return <Error error={savedEntriesError}/>
    }

    return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', padding: 1/*, border: '1px solid'*/ }}>
        <FormControl 
            component="fieldset" 
            variant="standard"
        >
            <FormGroup 
                row 
                sx={{
                    gap: 6,
                    alignItems: 'center'
                }}
            >
                <TickerSelector
                    sx={{variant: 'filled', width: 150/*, border: "1px solid"*/}}
                    tickerList={tickerList}
                    label={"Ticker"}
                    value={ticker}
                    onChange={handleTickerSelected}
                />

                <DatePicker
                    shouldDisableDate={shouldDisableDate}
                    value={date ? dayjs(date) : null}
                    label={"Date"}
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { size: 'small', variant: 'filled', style: {width : 200 }} }}
                    onChange={handleDateChanged}
                />

                <DatePicker
                    shouldDisableDate={shouldDisableExpFrom}
                    label={"First Expiration Date"}
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { size: 'small', variant: 'filled', style: {width : 200 }} }}
                    value={expirationFrom ? dayjs(expirationFrom) : null}
                    onChange={handleExpirationFromChanged}
                    referenceDate={date ? dayjs(date) : dayjs()}
                />

                <DatePicker
                    shouldDisableDate={shouldDisableCutoff}
                    label={"Last Expiration Date"}
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { size: 'small', variant: 'filled', style: {width : 200 }} }}
                    value={cutoff ? dayjs(cutoff) : null}
                    onChange={handleCutoffChanged}
                    referenceDate={expirationFrom ? dayjs(expirationFrom) : dayjs()}
                />

                <Button 
                    variant="contained" 
                    color="secondary" 
                    size="small" 
                    onClick={handleReset}
                    sx={{width: 80}}>
                        RESET
                </Button>

                <Button 
                    disabled={!ticker || !date || !expirationFrom}
                    type="submit" 
                    variant="contained" 
                    color="primary" 
                    size="small" 
                    sx={{width: 80}}>
                        RUN
                </Button>
            </FormGroup>
        </FormControl>
        </Box>
    </LocalizationProvider>
    );
}